<template>
    <form 
        class="card-form edit-user-scope flex-container flex-col" 
        @submit.prevent="save" 
        ref="form"
    >
        <div 
            class="layout-content-header pt24 pl24 pr24 infotronik_publisher_header"
        >
            <h1 
                class="nomargin bebas"
            >
                {{scope.name}}
            </h1>
        </div>
   
        <div class="content p24 flex-container flex-col infotronik_publisher_schermatapublisher">
            <div class="flex-container">
                <div class="flex-container flex-col pr16"> 
                    <div class="flex-container flex-col mb24">
                        <text-input
                            :label="'nome'"
                            :value="scope.name"
                            :editing="editing"
                            :name="'name'"
                            :placeholder="scope.name"
                            :className="'flex-container flex-col flex-0 mb8'"
                        >
                        </text-input>
                        <select-input
                            :label="'Tipologia'"
                            :value="scope.discriminator"
                            :editing="false"
                            :name="'discriminator'"
                            :id="'discriminator'"
                            :placeholder="'scegli il tipo'"
                            :options="['Publisher', 'Concessionaire']"
                        ></select-input>
                        <text-input
                            v-if="concessionaire"
                            :label="'Concessionaria'"
                            :value="concessionaire.name"
                            :editing="false"
                        ></text-input>
                        
                         <text-area-input
                            :label="'note'"
                            :value="scope.note"
                            :editing="editing"
                            :placeholder="''"
                            :name="'note'"
                            :className="'flex-container flex-col mb8'"
                            :required="false"
                        ></text-area-input>
                        
                    </div>
                </div>
                <FileUpload
                    :editing="editing"
                    :fileurl ="scope?.logo?.id ? API_BASE +'/file/'+ scope.logo.id : ''"
                    @image-selected="onImageSelected"
                    class="infotronik_publisher_imagecontainer"
                ></FileUpload>
            </div>
           
            <label
                v-if="publishers"
                class="flex-container flex-col flex-0 mb8"
            >
                <div class="flex-container heading-and-error flex-start-align no-grow">
                    <h3 class="bebas nomargin">Publisher Associati</h3>
                    
                </div>
                <ul class="nopadding layout-list">
                    <UserScopeListElement
                        v-for="publisher in publishers"
                        :key="publisher.id"
                        :scope="publisher"
                    ></UserScopeListElement>
                </ul>
            </label>
            <label
                v-if="socials"
                class="flex-container flex-col flex-0 mb8"
            >
                <div class="flex-container heading-and-error flex-start-align no-grow">
                    <h3 class="bebas nomargin">Pagine Associate</h3>
                </div>
                <ul class="nopadding layout-list">
                    <SocialListElement
                        v-for="social in socials"
                        :key="social.socialId"
                        :social="social"
                    ></SocialListElement>
                </ul>
            </label>
        </div>
         <form-footer
            @edit-enable="editing = true"
            @edit-disable="disableEdit"
            @submit-form="save"
            @delete-press="onDeleteClick"
            :editing="editing"
        ></form-footer>
    </form>
     <ConfirmPanel
        v-if="confirmVisible"
        :title="'Elimina ' + scope.name"
        :message="'sei sicuro di voler eliminare ' + scope.name + '?'"
        @allow-confirm="onDeleteConfirm"
        @deny-confirm="confirmVisible = false"
    >
    </ConfirmPanel>
</template>
<script>
import {ref, reactive} from 'vue'
import{useStore} from 'vuex'
import {useRouter} from 'vue-router'
import TextInput from '@/components/formelements/TextInput'
import SelectInput from '@/components/formelements/SelectInput'
import FormFooter from '@/components/formelements/FormFooter'
import ConfirmPanel from '@/components/ConfirmPanel'
import FileUpload from '@/components/FileUpload'
import TextAreaInput from '@/components/formelements/TextAreaInput'
import UserScopeListElement from '@/components/userscope/UserScopeListElement'
import SocialListElement from '@/components/social/SocialListElement'
import { API_BASE, /* ApiEndPoints  */} from '@/api/Endpoints'
export default {
    props:{
        selectedScope:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        TextInput,
        SelectInput,
        FormFooter,
        ConfirmPanel,
        FileUpload,
        UserScopeListElement,
        SocialListElement,
        TextAreaInput
    },
    setup(props){
        
        const store = useStore() 
        const router = useRouter()
        const form = ref(null)
        const editing =ref(false)
        const scope = reactive(props.selectedScope)
        const concessionaire = scope.concessionaire ? ref(scope.concessionaire) : ref(null)
        const publishers = scope.discriminator === 'Concessionaire' ? scope.publishers : null
        const socials = scope.discriminator === 'Publisher' ? scope.socials : null
        
        const save = async()=>{
            let hasLogo = true
            if(logo.value){
                hasLogo = false
                try {
                    await uploadLogo()
                    hasLogo = true
                } catch (error) {
                    await store.dispatch('messages/message', {
                        title:'error uploading userscope image',
                        message:error.message
                    })
                }
                
            }
            if(hasLogo){
                const formData = new FormData(form.value)
            
                for (let key of formData.keys()) {
                    scope[key] = formData.get(key)
                }

                const data = {...scope}
                
                delete data.socials
                delete data.concessionaire
            
                try {
                    await store.dispatch('userscope/update', {
                        id:scope.id,
                        data:data, 
                    })
                    await store.dispatch('messages/message', {
                        title: scope.name,
                        message:'modifica eseguita correttamente'
                    })
                } catch (error) {
                    await store.dispatch('messages/message', {
                        title:'error updating userscope', 
                        message:error.message 
                    })
                } 
            }           
              
        }

        const confirmVisible = ref(false)
        
        const onDeleteClick = async()=>{
            
            confirmVisible.value=true
        }
        const onDeleteConfirm  = async()=>{
            
            try {
                await store.dispatch('userscope/remove', props.selectedScope.id)
                await store.dispatch('messages/message', {
                    title:'userscope eliminato',
                    message:'hai cancellato ' + props.selectedScope.name
                })
                router.push('/userscopes')
                //location.reload();
                window.location = "/userscopes";
                

            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore nella eliminazione userscope',
                    message:error.message
                })
            }
        }
        const logo = ref(null)
        const onImageSelected =(files)=>{
            logo.value = files[0]
        }
        const uploadLogo = async() => {
            await store.dispatch('userscope/upload', {
                logo:logo.value, 
                id:scope.id
            }) 
        }

        const disableEdit = async ()=> { 

            const singleUserScope = await store.dispatch('userscope/getSingle', props.selectedScope.id)
            Object.keys(scope).forEach(key =>{
                scope[key] = singleUserScope[key]
            })
            editing.value = false
            //keyCounter.value++
        }
        
        return{
            form,
            save,
            editing,
            scope,
            confirmVisible,
            onDeleteClick,
            onDeleteConfirm,
            concessionaire,
            onImageSelected,
            API_BASE,
            disableEdit,
            publishers,
            socials
        }
    }
}
</script>
<style lang="postcss">
    .layout-content input[type="submit"].headerbadge{
        line-height: 30px;
    }
</style>