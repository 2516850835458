<template>
    <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">PUBLISHER</span>
        <a href="#" class="btn-new" @click.prevent="show">+</a>
        <UserScopeWizard v-if="createPanelVisible" @close="hide()"></UserScopeWizard>
    </div>
    <ScopeTypes :filterPath="'userscope/filter'"></ScopeTypes>    
</template>
<script>
import {ref} from 'vue'
import {useStore} from 'vuex'
//import CreateUserButtons from '@/components/user/CreateUserButtons'
import ScopeTypes from '@/components/userscope/ScopeTypes'
import UserScopeWizard from '@/components/userscope/UserScopeWizard'
export default {
    components:{
        ScopeTypes,
        UserScopeWizard
    },
    setup(){
        const store = useStore()
        const createPanelVisible = ref(false)
        
        const show= ()=>{
            createPanelVisible.value = true
        }
        const hide = ()=>{
            createPanelVisible.value = false
        }
        const createUserScope = async(scope)=>{
            
            await store.dispatch('userscope/createUserScope', scope)
            //await store.dispatch('user/createUser', user)
        }

        return{
            show,
            hide,
            createPanelVisible,
            createUserScope
        }
    }
}
</script>
<style>
.downup-leave-active,.downup-enter-active {
    transition: .2s ease-in-out;
}
.downup-enter-to {
    top: 0px;
}
.downup-enter-from,.downup-leave-to {
    top: -200px;
} 
</style>