<template>
  

 <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">PUBLISHER</span>
        <a href="#" class="btn-new" @click.prevent="show">+</a>
        <UserScopeWizard v-if="createPanelVisible" @close="hide()"></UserScopeWizard>
    </div>
 <ScopeTypes2 
    :filterPath="'userscope/filter'"
    class="infotronik_publisher_filterlist"
    ></ScopeTypes2> 

    <ListHeader
        :defaultSort="defaultSort"
        @input="onInput"
        @change="onSort"
    ></ListHeader>




  <div class="layout-list-content infotronik_publisher_lista">
        <ul class="layout-list nopadding nomargin"> 
            <UserScopeListElement 
                v-for="scope in scopes" 
                :key= "scope.id" 
                :scope="scope" 
                :selectedId="selectedId">
            ></UserScopeListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>
import {ref} from 'vue'
import {computed} from 'vue'
import {useStore} from 'vuex'
import UserScopeListElement from '@/components/userscope/UserScopeListElement'
import ListHeader from '@/components/ListHeader'
import ScopeTypes2 from '@/components/userscope/ScopeTypes2'
import Pagination from '@/components/Pagination'
import UserScopeWizard from '@/components/userscope/UserScopeWizard'
export default {
    props:{
        scopes:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:Number,
            default:null
        },
        defaultSort: {
            type: String,
            default: 'Nome asc'
        }
    },
    async mounted() {
        const defaultSorting = { type: 'Nome asc' };
        this.onSort({ target: { value: defaultSorting } });
    },
    data() {
        return {
            selectedSort: this.defaultSort
        }
    },
    components:{
        UserScopeListElement,
        ListHeader,
        Pagination,
        ScopeTypes2,
        UserScopeWizard
    },
    setup(){

        const store = useStore()

        const createPanelVisible = ref(false)
        
        const show= ()=>{
            createPanelVisible.value = true
        }
        const hide = ()=>{
            createPanelVisible.value = false
        }
        const createUserScope = async(scope)=>{
            
            await store.dispatch('userscope/createUserScope', scope)
            //await store.dispatch('user/createUser', user)
        }



      
        
        const onInput = (filter)=>{
            store.dispatch('userscope/filter', filter) 
        }
        const onSort =(sorting)=>{        
            store.dispatch('userscope/sort', sorting)
        }
        const onPageChange = async (page)=>{
            await store.dispatch('userscope/userScopes', {PageNumber:page})
        }
        const pagination = computed(()=>{
            return store.getters['userscope/pagination']
        })
        
        return{
           onPageChange,
            onSort,
            onInput,
            pagination,
            ScopeTypes2,
            UserScopeWizard,
            show,
            hide,
            createUserScope,
            createPanelVisible
        }
    }
}
</script>