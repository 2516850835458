<template>
    <li class="layout-list-item">
        <router-link 
            :to="'/userscope/'+ scope.id" 
            :class="selected ? 'layout-list-item-btn flex-container pl16 pr16 selected' : 'layout-list-item-btn flex-container pl16 pr16'"
            @click="openProfile2()"
        >
            
            <div 
                v-if="scope.logo" 
                class="layout-list-icon user-icon"
                :style="`background-image:url('${API_BASE +'/file/'+ scope.logo.id}')`"
            >
            </div>
            <div v-else class="layout-list-icon user-icon"></div>

            <div class="flex-container flex-col layout-list-item-details-container">
                <p class="pl8 layout-list-item-title nomargin">{{scope.name}}</p>
                <div class="flex-container">
                    <p class="pl8 layout-list-item-subtitle nomargin f12">
                        <span>{{scope.discriminator !== 'Concessionaire' ? scope.discriminator : 'Concessionaria'}} </span>
                    </p>
                </div>
            </div>
        </router-link>
    </li>
</template>
<script>
import { ref } from 'vue';
import {computed} from 'vue'
import { API_BASE } from '@/api/Endpoints'
export default {

    methods: {
    openProfile2() { 

  
     /////emetto nascondendo o meno le colonne////
     this.emitter.emit('Userscopesmostralista',false); 
     this.emitter.emit('UserscopesDettaglilista',true);
   

    
     ////////////breadcrumb//////////////
     //creo il breadcrumb per l'utente///
    this.routes = ref([
      //{ name: 'Home', path: '/' },
      { name: 'publisher', path: '/userscopes' }
      //{ name: usern, path: '/user/'+userid }
    ]);
    ////////////breadcrumb//////////////
    //creo il breadcrumb per l'utente///
   
     this.emitter.emit('breadcrumb',this.routes);

     /////////////////////////////////////

    },
 },


    emits:['list-select'],
    props:{
        scope:{
            type:Object,
            default:()=>{}
        } ,
        selectedId:{
            type:Number,
            default:0
        },
    },
    setup(props,context){

        const usern = ref(null)
        /*  const routes = ref([
        { name: 'Home', path: '/' },
        { name: 'Utenti', path: '/users' },
        { name: usern.value, path: '/'+usern.value }
        ]);*/

        const routes = ref([
        //{ name: 'Home', path: '/' },
        { name: 'publisher', path: '/userscopes' }
        //{ name: usern, path: '/user/'+userid }
        ]);

  

     

        //const {selectedUser} = useUserFunctions()
        const onSelect=()=>{
            context.emit('list-select', props.scope)
        }
        const selected = computed(()=>{
            return props.selectedId === props.scope.id
        })
        return{
            onSelect,
            selected,
            routes,
            API_BASE,
            usern
        }
    }
    
}
</script>